/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import AppContextProvider from "./src/utils/context"
import { AuthProvider } from "./src/context/authContext"
import { LangProvider } from "./src/context/langContext"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
    return (
        <LangProvider>
            <AppContextProvider>
                <AuthProvider>{element}</AuthProvider>
            </AppContextProvider>
        </LangProvider>
    )
}
