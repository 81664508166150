import React, { useState } from "react"

export const AppContext = React.createContext({
  isMobileMenuVisible: false,
  setMobileMenuVisible: () => {},
})

const AppContextProvider = ({ children }) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false)
  return (
    <AppContext.Provider
      value={{
        isMobileMenuVisible,
        setIsMobileMenuVisible,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
