import { navigate } from "gatsby"
import React, {
    createContext,
    useState,
    useContext,
    useLayoutEffect,
} from "react"

const LangContext = createContext()

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState("en")

    const setSessionLang = language => {
        if (language !== lang) {
            window.sessionStorage.setItem("kotwis-lang", language)
            setLang(language)
            if (language === "en") {
                navigate(`${window.location.pathname.replaceAll("/id/", "/")}`)
            } else {
                navigate(`/${language}${window.location.pathname}`)
            }
        }
    }

    const value = {
        lang,
        setLang: setSessionLang,
    }

    useLayoutEffect(() => {
        const langSession = window.sessionStorage.getItem("kotwis-lang")
        setLang(langSession ? langSession : "en")
    }, [])

    return <LangContext.Provider value={value}>{children}</LangContext.Provider>
}

export const useLangContext = () => {
    return useContext(LangContext)
}
