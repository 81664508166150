exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-investors-area-annual-reports-js": () => import("./../../../src/pages/about/investors-area/annual-reports.js" /* webpackChunkName: "component---src-pages-about-investors-area-annual-reports-js" */),
  "component---src-pages-about-investors-area-boc-bod-js": () => import("./../../../src/pages/about/investors-area/boc-bod.js" /* webpackChunkName: "component---src-pages-about-investors-area-boc-bod-js" */),
  "component---src-pages-about-investors-area-coorporate-governance-js": () => import("./../../../src/pages/about/investors-area/coorporate-governance.js" /* webpackChunkName: "component---src-pages-about-investors-area-coorporate-governance-js" */),
  "component---src-pages-about-investors-area-financial-reports-js": () => import("./../../../src/pages/about/investors-area/financial-reports.js" /* webpackChunkName: "component---src-pages-about-investors-area-financial-reports-js" */),
  "component---src-pages-about-investors-area-index-js": () => import("./../../../src/pages/about/investors-area/index.js" /* webpackChunkName: "component---src-pages-about-investors-area-index-js" */),
  "component---src-pages-about-investors-area-information-disclosure-js": () => import("./../../../src/pages/about/investors-area/information-disclosure.js" /* webpackChunkName: "component---src-pages-about-investors-area-information-disclosure-js" */),
  "component---src-pages-about-investors-area-investor-relations-js": () => import("./../../../src/pages/about/investors-area/investor-relations.js" /* webpackChunkName: "component---src-pages-about-investors-area-investor-relations-js" */),
  "component---src-pages-about-investors-area-organization-structure-js": () => import("./../../../src/pages/about/investors-area/organization-structure.js" /* webpackChunkName: "component---src-pages-about-investors-area-organization-structure-js" */),
  "component---src-pages-about-investors-area-press-release-js": () => import("./../../../src/pages/about/investors-area/press-release.js" /* webpackChunkName: "component---src-pages-about-investors-area-press-release-js" */),
  "component---src-pages-about-sustainability-index-js": () => import("./../../../src/pages/about/sustainability/index.js" /* webpackChunkName: "component---src-pages-about-sustainability-index-js" */),
  "component---src-pages-about-sustainability-single-js": () => import("./../../../src/pages/about/sustainability/single.js" /* webpackChunkName: "component---src-pages-about-sustainability-single-js" */),
  "component---src-pages-access-index-id-js": () => import("./../../../src/pages/access/index.id.js" /* webpackChunkName: "component---src-pages-access-index-id-js" */),
  "component---src-pages-access-index-js": () => import("./../../../src/pages/access/index.js" /* webpackChunkName: "component---src-pages-access-index-js" */),
  "component---src-pages-contact-us-id-js": () => import("./../../../src/pages/contact-us.id.js" /* webpackChunkName: "component---src-pages-contact-us-id-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-developments-masterplan-js": () => import("./../../../src/pages/developments/masterplan.js" /* webpackChunkName: "component---src-pages-developments-masterplan-js" */),
  "component---src-pages-developments-type-getmoreinfo-js": () => import("./../../../src/pages/developments/type/getmoreinfo.js" /* webpackChunkName: "component---src-pages-developments-type-getmoreinfo-js" */),
  "component---src-pages-developments-type-index-js": () => import("./../../../src/pages/developments/type/index.js" /* webpackChunkName: "component---src-pages-developments-type-index-js" */),
  "component---src-pages-developments-type-single-js": () => import("./../../../src/pages/developments/type/single.js" /* webpackChunkName: "component---src-pages-developments-type-single-js" */),
  "component---src-pages-developments-type-siteplan-js": () => import("./../../../src/pages/developments/type/siteplan.js" /* webpackChunkName: "component---src-pages-developments-type-siteplan-js" */),
  "component---src-pages-e-catalog-js": () => import("./../../../src/pages/e-catalog.js" /* webpackChunkName: "component---src-pages-e-catalog-js" */),
  "component---src-pages-facilities-index-id-js": () => import("./../../../src/pages/facilities/index.id.js" /* webpackChunkName: "component---src-pages-facilities-index-id-js" */),
  "component---src-pages-facilities-index-js": () => import("./../../../src/pages/facilities/index.js" /* webpackChunkName: "component---src-pages-facilities-index-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-id-js": () => import("./../../../src/pages/index.id.js" /* webpackChunkName: "component---src-pages-index-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-index-id-js": () => import("./../../../src/pages/services/index.id.js" /* webpackChunkName: "component---src-pages-services-index-id-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-single-js": () => import("./../../../src/pages/services/single.js" /* webpackChunkName: "component---src-pages-services-single-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-access-access-single-template-js": () => import("./../../../src/templates/access/access-single-template.js" /* webpackChunkName: "component---src-templates-access-access-single-template-js" */),
  "component---src-templates-developments-development-project-moreinfo-template-js": () => import("./../../../src/templates/developments/development-project-moreinfo-template.js" /* webpackChunkName: "component---src-templates-developments-development-project-moreinfo-template-js" */),
  "component---src-templates-developments-development-project-siteplan-template-js": () => import("./../../../src/templates/developments/development-project-siteplan-template.js" /* webpackChunkName: "component---src-templates-developments-development-project-siteplan-template-js" */),
  "component---src-templates-developments-development-project-template-js": () => import("./../../../src/templates/developments/development-project-template.js" /* webpackChunkName: "component---src-templates-developments-development-project-template-js" */),
  "component---src-templates-developments-development-type-list-template-js": () => import("./../../../src/templates/developments/development-type-list-template.js" /* webpackChunkName: "component---src-templates-developments-development-type-list-template-js" */),
  "component---src-templates-facilities-facility-cat-template-js": () => import("./../../../src/templates/facilities/facility-cat-template.js" /* webpackChunkName: "component---src-templates-facilities-facility-cat-template-js" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-single-template-js": () => import("./../../../src/templates/news-single-template.js" /* webpackChunkName: "component---src-templates-news-single-template-js" */)
}

